<template>
  <div>
    <a-modal :visible="visible" title="生产工单" :width="1200" :footer="null" @cancel="cancel">
      <a-table rowKey="id" size="small" :columns="columns" :dataSource="dataItems" :loading="dataLoading" :pagination="false">
        <template slot="number" slot-scope="value">
          <a @click="detial(item)">{{ value }}</a>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { goodsProductionOrderList } from "@/api/report";

export default {
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      dataLoading: false,
      dataItems: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "生产工单号",
          dataIndex: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "计划数量",
          dataIndex: "total_quantity",
        },
        {
          title: "计划开始时间",
          dataIndex: "planned_start_time",
        },
        {
          title: "计划完成时间",
          dataIndex: "planned_end_time",
        },
        {
          title: "产品名称",
          dataIndex: "product_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "产品编号",
          dataIndex: "product_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "产品条码",
          dataIndex: "product_barcode",
          customRender: (_, item) => item.goods_item.barcode,
        },
        {
          title: "产品规格",
          dataIndex: "product_spec",
          customRender: (_, item) => item.goods_item.spec,
        },
      ],
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    detial(item) {
      this.$router.push({ path: "/production/detial", query: { id: item.id } });
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.dataLoading = true;
        goodsProductionOrderList({ id: this.item.id })
          .then((data) => {
            this.dataItems = data;
          })
          .finally(() => (this.dataLoading = false));
      }
    },
  },
};
</script>

<style scoped></style>
